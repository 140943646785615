<template>
    <div class="small">
        <bar :chart-data="datacollection"
                    :options="options"
                    :styles="{height: '300px', position: 'relative', width: '100%', 'margin-top': '10px'}">
        </bar>
    </div>
</template>

<script>
  import Bar from './BarChart.js';

  export default {
    name: 'BarChart',
    components: {
      Bar
    },
    data() {
      return {
        datacollection: null,
        items: 30,
        options: {
          showLines: true,
          animation: false,
          cubicInterpolationMode: 'basis',
          scales: {
            xAxes: [{
              ticks: {
                minRotation: 50,
                fontSize: 11,
                stepSize: 1,
                autoSkip: false
              }
            }],
            yAxes: [{
              ticks: {
                max: 40000,
                reverse: this.reverse
              }
            }]
          },
          offset: true,
          layout: {
            padding: 5
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 0,
          legend: {
            display: false
          },
          tooltips: {
            animation: true,
            mode: 'point',
            intersect: true
          }
        }
      };
    },
    mounted() {
      this.fillData();
    },
    methods: {
      fillData() {
        const datacollection = {
          labels: [],
          datasets: []
        };
        for (let i = 1; i < this.items; i++) {
          datacollection.labels.push(i + ' May');
          datacollection.datasets.push({
            label: i + ' May',
            backgroundColor: '#47649d',
            data: [8000, 8500, 10000, 26000, 26500, 25500, 8000, 8900, 8700, 8800, 9000, 10000, 26000, 26500, 25500, 8900, 8700, 8800, 8000, 8500, 8900, 26000, 26500, 25500, 8000, 8900, 8700, 8800, 8500, 8900, 26000, 26500, 25500, 8900, 8700, 8800, 8000, 8500, 8900, 26000, 26500, 25500, 8000, 8900, 8700, 8800, 8500, 8900, 26000, 26500, 25500, 8900, 8700, 8800]
          });
        }
        this.datacollection = datacollection;
      }
    }
  };
</script>

<style>
    .small {
        max-width: 600px;
        margin:  0 auto;
    }
</style>